import {
  AssetsProvider,
  Image,
  Link,
  notDesktop,
  styled,
  Text,
} from '@obvio/app'
import { SvgBookmark } from '@obvio/svg'
import { Button, Grid, Stack } from '@obvio/ui'
import { motion } from 'framer-motion'

import { CtaLink } from '@/components/CtaLink'
import { dateFormatter, normalizeContent, timeToRead } from '@/utils/articles'
import { useArticlesLatest } from '@/utils/hooks/useArticlesLatest'

import type { ImageAsset } from '@obvio/app'
import type { AllowNullishForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type ArticlesProps = {
  title?: string
  button?: string
  limit?: number
}

const Wrap = motion(styled(Stack)`
  overflow: hidden;
`)

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${notDesktop} {
    gap: ${(theme) => theme.spacing.big};
    flex-direction: column;
  }
`

const ArticleWrap = motion(styled(Grid)`
  width: 100%;
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`)

const ImageWrapper = styled.div`
  min-height: 13.125rem;
`

const Divider = styled.div`
  min-height: 1px;
  width: 100%;
  background: ${(theme) => theme.stroke};
  margin-top: ${(theme) => theme.spacing.big};
`

const ArticleText = styled(Stack)`
  > p:first-of-type {
    flex: 1;
  }
`

const CenterStack = styled(Stack)`
  align-items: center;
`

type ArticleProps = AllowNullishForOptional<{
  id: string
  title: string
  image: ImageAsset
  slug: string
  createdAt: Date
  seoDescription?: string
  content: any[]
  idx?: number
}>

const transforms = [
  'rotate(5deg) translateY(1rem) translateX(-4rem)',
  'rotate(-7deg) translateY(-1rem) translateX(4rem)',
  'rotate(-3deg) translateY(1rem) translateX(-4rem)',
]

function Article({
  id,
  title,
  seoDescription,
  image,
  slug,
  content,
  createdAt,
  idx = 0,
}: ArticleProps) {
  return (
    <ArticleWrap
      templateColumns="9rem 18.2rem 1fr"
      gap="extraLarge"
      initial="initial"
      whileHover="hover"
    >
      <Text tag="p">{dateFormatter.format(createdAt)}</Text>
      <AssetsProvider path={`/article/${id}`}>
        <ImageWrapper>
          <motion.div
            variants={{
              initial: {
                transform: transforms[(idx ?? 0) % transforms.length],
                opacity: 0,
              },
              hover: {
                transform: 'rotate(0deg) translateY(0rem) translateX(0rem)',
                opacity: 1,
              },
            }}
            transition={{ duration: 0.3 }}
            style={{ width: '100%', height: '100%' }}
          >
            <Image img={image} sizes="300px" />
          </motion.div>
        </ImageWrapper>
      </AssetsProvider>
      <Link href={`/blog/${slug}`}>
        <ArticleText kind="vertical">
          <Text tag="h3">{title}</Text>
          <Text>{seoDescription || normalizeContent(content)}</Text>
          <CenterStack>
            <SvgBookmark />
            <Text as="note">{timeToRead(content)} min czytania</Text>
          </CenterStack>
        </ArticleText>
      </Link>
    </ArticleWrap>
  )
}

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -10 },
}

export function ArticlesLatest({
  title,
  button,
  limit,
}: ArticlesProps): ReactElement {
  const { data } = useArticlesLatest(limit)

  return (
    <Wrap
      kind="vertical"
      spacing="big"
      initial="hidden"
      whileInView="visible"
      transition={{
        when: 'beforeChildren',
        staggerChildren: 0.35,
        delayChildren: 0.5,
      }}
      viewport={{
        once: true,
      }}
    >
      <TopBar>
        <Text tag="h2">{title}</Text>
        <div>
          {button ? (
            <Button kind="secondary">
              <CtaLink href="/blog" text={button} />
            </Button>
          ) : null}
        </div>
      </TopBar>
      <Divider />
      {data?.map((article, idx) => (
        <motion.div key={article.id} variants={item}>
          <Article {...article} idx={idx} />
          <Divider />
        </motion.div>
      ))}
    </Wrap>
  )
}
